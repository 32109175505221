<template>
  <v-app id="view-assessment-instance-items">
    <v-data-table
      :header-props="{ sortIcon: 'mdi-chevron-down' }"
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="items"
      :must-sort="true"
      :loading="isBusy"
      disable-pagination>
      <template v-slot:[`item.item_id`]="{ item }">
        <router-link :to="{ path: '/items/edit', query: {
            mode: 'update',
            id: item.item_id,
            bucket: item.bucket,
            domain: item.domain,
            skill: item.skill,
            template: item.template_type } }">
          {{ item.item_id }}
        </router-link>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-switch :disabled="true" v-model="item.active" />
      </template>
      <template v-slot:[`item.date_retired`]="{ item }">
        <span>{{ item.date_retired | dateString }}</span>
      </template>
    </v-data-table>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AssessmentInstanceItems',
  computed: {
    ...mapGetters({
      isBusy: 'assessmentInstances/isBusy',
      headers: 'assessmentInstances/itemsTableHeaders',
      assessmentInstanceItems: 'assessmentInstances/assessmentInstanceItems',
    }),
    items() {
      const { assessmentInstanceId } = this.$route.params
      if (!assessmentInstanceId) return [];
      return this.assessmentInstanceItems(assessmentInstanceId)
    }
  },
  methods: {
    ...mapActions({
      fetchAssessmentInstanceItems: 'assessmentInstances/fetchAssessmentInstanceItems',
    })
  },
  mounted() {
    const { assessmentInstanceId } = this.$route.params;
    this.fetchAssessmentInstanceItems({ assessmentInstanceId })
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  & .action-item {
    margin-right: .5em;
  }
}
.filter-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding: 1em;
    & .error {
      margin: 0;
      padding: 0;
    }
    & form {
      display: flex;
      flex-direction: row;
      & > div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        & > div {
          margin-right: 1em;
        }
      }
      & > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        & .v-input {
          margin-right: 1em;
          min-width: 100%;
        }
        & .v-btn {
          margin-right: 1em;
        }
      }
      & button {
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }
  }
</style>